import React, { useState } from "react";
import { storage, db } from "../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";

import QRCodeGenerator from "./QRCodeGenerator";
import InputFilePdf from "./InputFilePdf";

const UploadFile = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [qrCodeUrl, setQRCodeUrl] = useState("");

  const handleUpload = async () => {
    if (!file) return;

    const storageRef = ref(storage, `documents/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Error al subir archivo:", error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const collectionRef = collection(db, "files");

          const q = query(collectionRef, where("name", "==", file.name));
          const querySnapshot = await getDocs(q);

          let saveDoc;
          if (!querySnapshot.empty) {
            const docRef = doc(db, "files", querySnapshot.docs[0].id);
            saveDoc = querySnapshot.docs[0];
            await updateDoc(docRef, {
              name: file.name,
              url: downloadURL,
            });
            console.log("Documento actualizado exitosamente");
          } else {
            saveDoc = await addDoc(collectionRef, {
              name: file.name,
              url: downloadURL,
            });
            console.log("Documento creado exitosamente");
          }
          const basePath = window.location.origin;
          setQRCodeUrl(basePath.concat("/pdf/").concat(saveDoc.id));
        } catch (error) {
          console.error("Error al guardar URL en Firestore:", error);
        }
      }
    );
  };

  return (
    <div className="p-4 w-100">
      <InputFilePdf setFile={setFile} setProgress={setProgress} />
      <button className="btn-upload-file" onClick={handleUpload}>
        Subir PDF {progress == 0 ? "" : progress + "%"}
      </button>
      <hr />
      {qrCodeUrl != "" ? (
        <QRCodeGenerator url={qrCodeUrl} fileName={file.name} />
      ) : (
        <hr />
      )}
    </div>
  );
};

export default UploadFile;
