import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { auth } from "./firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

import Home from "./components/Home";
import GoogleSignIn from "./components/GoogleSignIn";
import ProtectedRoute from "./components/ProtectedRoute";
import RouteNotMapped from "./components/RouteNotMapped";
import PDFViewer from "./components/PDFViewer";

import { allowedUsers } from "./allowedUsers";

import "./App.css";

function App() {
  const [user] = useAuthState(auth);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<GoogleSignIn />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                {user && allowedUsers.includes(user.email) ? (
                  <Home />
                ) : (
                  <Navigate to="/" />
                )}
              </ProtectedRoute>
            }
          />
          <Route path="/pdf/:fileKey" element={<PDFViewer />} />
          <Route path="*" element={<RouteNotMapped />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
