import React, { useEffect, useState } from "react";

import { db } from "../../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";

import { DocumentItem } from "./DocumentItem";

export const DocumentList = ({ setIsShadow }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const basePath = window.location.origin;

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "files"), (snapshot) => {
      const files = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFiles(files);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <h1 className="mt-4">Cargando...</h1>;
  } else {
    return (
      <div className="document-list mb-9">
        {files.map((file) => (
          <DocumentItem
            key={file.id}
            name={file.name}
            url={basePath.concat("/pdf/").concat(file.id)}
            setIsShadow={setIsShadow}
            docId={file.id}
          />
        ))}
      </div>
    );
  }
};
